var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-body"},[_c('FullCalendar',{ref:"fullCalendar",attrs:{"locale":"cs","height":_vm.windowHeight-210,"firstDay":1,"plugins":_vm.calendarPlugins,"header":{
                            left: 'title',
                            center: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
                            right: 'today prev next'
                        },"buttonText":{
                            today: 'Dnes',
                            month: 'Měsíc',
                            week: 'Týden',
                            day: 'Den',
                            list: 'Agenda',
                        },"events":_vm.issues,"droppable":!_vm.user.cus,"selectable":!_vm.user.cus,"editable":!_vm.user.cus},on:{"eventClick":_vm.handleIssueClick,"eventResize":_vm.updateCalIssue,"eventDrop":_vm.updateCalIssue,"select":_vm.addIssueFromCalendar}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xs-12 visible-xs"},[_c('div',{staticClass:"alert alert-warning alert-dismissible"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"alert","aria-hidden":"true"}},[_vm._v("×")]),_c('h4',[_c('i',{staticClass:"icon fa fa-warning"}),_vm._v("Pozor!")]),_vm._v(" V režimu pro mobilní telefon se kalendář nemusí vhodně zobrazovat. Pro jeho využití, ve svém prohlížeči prosím zapněte režim počítače. ")])])
}]

export { render, staticRenderFns }