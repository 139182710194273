<template>
    <div>
        <div class="row">
            <!-- <div class="col-md-3">
                <div class="box box-solid">
                    <div class="box-header with-border">
                    <h4 class="box-title">Úkony bez data</h4>
                    </div>
                    <div class="box-body">
                    <div id="external-events">
                        <div v-for="issue in noDateIssues" :key="issue.id" class="external-event bg-light-blue">{{issue.title}}</div>
                        <div class="checkbox">
                        <label for="drop-remove">
                            <input type="checkbox" id="drop-remove">
                            remove after drop
                        </label>
                        </div>
                    </div>
                    </div>
                </div>
            </div> -->
            <div class="col-xs-12 visible-xs">
                <div class="alert alert-warning alert-dismissible">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                <h4><i class="icon fa fa-warning"></i>Pozor!</h4>
                V režimu pro mobilní telefon se kalendář nemusí vhodně zobrazovat. Pro jeho využití, ve svém prohlížeči prosím zapněte režim počítače. 
              </div>
            </div>
            <div class="col-xs-12">
                <div class="box">
                    <div class="box-body">
                        <FullCalendar
                            ref="fullCalendar"
                            locale="cs"
                            :height="windowHeight-210"
                            :firstDay="1" 
                            :plugins="calendarPlugins"
                            :header="{
                                left: 'title',
                                center: 'dayGridMonth, timeGridWeek, timeGridDay, listWeek',
                                right: 'today prev next'
                            }"
                            :buttonText="{
                                today: 'Dnes',
                                month: 'Měsíc',
                                week: 'Týden',
                                day: 'Den',
                                list: 'Agenda',
                            }"
                            :events="issues"
                            :droppable="!user.cus"
                            :selectable="!user.cus"
                            :editable="!user.cus"
                            @eventClick="handleIssueClick"
                            @eventResize="updateCalIssue"
                            @eventDrop="updateCalIssue"
                            @select="addIssueFromCalendar"
                        />
                    </div>
                </div>
            </div>
        </div> 
    </div>    
</template>

<script>
const name = 'Calendar';

import { mapGetters, mapActions } from "vuex";

// Import fullcalendar
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

/**
 * Events have to have these parameters
 *      title
 *      start
 *      end
 *      allDay
 */

export default {
    name,
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    computed: {
        ...mapGetters(['getAllPages', 'allIssues', 'user'])
    },
    data() {
        return {
            windowHeight: window.innerHeight,

            calendarPlugins: [ 
                dayGridPlugin, 
                timeGridPlugin, 
                interactionPlugin,
                listPlugin
            ],
            issues: [],
            noDateIssues: [],
        }
    },
    methods: {
        ...mapActions(['makeActiveTab', 'addTab', 'fetchIssues', 'makeCurrentIssue', 'updateIssue']),
        updateCalIssue(arg){
            this.updateIssue({
                issueid: arg.event.id,
                due_from: arg.event.start.getTime() / 1000,
                due_to: arg.event.end.getTime() / 1000
            })
        },
        handleIssueClick(arg){
            this.makeCurrentIssue(arg.event.id)
        },
        onResize() {
            this.windowHeight = window.innerHeight
        },
        addIssueFromCalendar(clickedCalendarDate) {
            let getCurrentDate = new Date()
            let today = new Date(getCurrentDate.getFullYear(), getCurrentDate.getMonth(), getCurrentDate.getDate(), 0, 0, 0);
            let aux = new Date(clickedCalendarDate.start.getTime())
            aux = aux.setHours('0', '0', '0')
            if (today <= aux) { // Cannot add operation before current date
                let unixMilisecs = Date.parse(clickedCalendarDate.end)
                clickedCalendarDate.end = new Date(unixMilisecs - 60000)
                this.$router.push({name: 'IssueAdd', params: {dates: clickedCalendarDate}})
            }
        }
    },
    created(){
        this.fetchIssues().then(() => {
            this.allIssues.forEach((item) => {
                // Sort issues which have specified dates
                if (item.due_from == null || item.due_to == null){
                    this.noDateIssues.push({
                        id: item.issueid,
                        title: item.name,
                    })
                } else {
                    this.issues.push({
                        id: item.issueid,
                        title: item.name,
                        start: new Date(item.due_from*1000),
                        end: new Date(item.due_to*1000),
                        backgroundColor: item.tag_color,
                        borderColor: item.tag_color,
                    })
                }
            });
        });

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    },
    mounted() {
        this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
        })
        if (sessionStorage.length) {
            this.$refs.fullCalendar.getApi().changeView(sessionStorage.getItem("calendarView"))
        }
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
        sessionStorage.setItem("calendarView", this.$refs.fullCalendar.getApi().state.viewType)
    }
    
}
</script>

<style lang='scss'>

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/interaction/main.css';
// @import '~@fullcalendar/list/main.css';

</style>
